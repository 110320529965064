import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Dashboard } from './components/Dashboard';
import { DashboardProvider } from './context/Dashboard/Provider';
import 'bootstrap/dist/css/bootstrap.min.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <DashboardProvider>
        <Dashboard />
    </DashboardProvider>
);
