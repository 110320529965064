import * as React from 'react';
import { Box, Paper, Divider, Autocomplete, createFilterOptions, TextField, Rating } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, Button, Fab } from '@mui/material';
import { Container, Col, Row } from 'react-bootstrap';
import './index.css';
import { CreateRaidModal } from '../CreateRaidModal';
import PokemonCard from '../PokemonCard'
import JoinRaidModal from '../JoinRaidModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tour from 'reactour'

//import Context
import { DashboardContext } from '../../context/Dashboard';

export function Dashboard() {
  const steps = [
    {
      selector: '#main_conteinter',
      content: 'Search for the desired raid'
    },
    {
      selector: '#raids_container',
      content: 'These are the raids that you can join'
    },
    {
      selector: '#reset_filter',
      content: 'Reset all filters'
    },
    {
      selector: '#post_new_raid',
      content: 'Create a new raid',
    },
    {
      selector: '#peepo_info',
      content: 'Contact with us'
    }
  ]

  const types = ['Any', 'Normal', 'Fighting', 'Flying', 'Poison', 'Ground',
    'Rock', 'Bug', 'Ghost', 'Steel', 'Fire', 'Water',
    'Grass', 'Electric', 'Psychic', 'Ice', 'Dragon',
    'Dark', 'Fairy'
  ];
  const {
    raids,
    raidsFiltered,
    setRaidsFiltered,
    getRaids,
    getPokemons,
    pokemonsData,
    pokemonsFiltered,
    setPokemonsFiltered
  } = React.useContext(DashboardContext)

  const [pokemonSelected, setPokemonSelected] = React.useState({ name: 'Any' });
  const [starSelected, setStarSelected] = React.useState('-1')
  const [teratypeSelected, setTeratypeSelected] = React.useState("Any")
  const [eventSelected, setEventSelected] = React.useState('-1')
  const [openAdd, setOpenAdd] = React.useState(false)
  const [openJoin, setOpenJoin] = React.useState(false)
  const [selectedRaid, setSelectedRaid] = React.useState({})
  const [backupRaid, setBackupRaid] = React.useState({})
  const [openInfo, setOpenInfo] = React.useState(false)

  const defaultFilterOptions = createFilterOptions();
  const handleAddClose = () => {
    setOpenAdd(false);
  }
  const handleAddOpen = () => setOpenAdd(true);
  const handleJoinClose = () => setOpenJoin(false);
  const handleJoinOpen = () => setOpenJoin(true);

  React.useEffect(() => {
    document.title = 'Peepo Raids';

    getRaids();
    getPokemons();

  }, []);

  React.useEffect(() => {
    filter();
  }, [starSelected, teratypeSelected, eventSelected, pokemonSelected]);


  React.useEffect(() => {
    function checkData() {
      var local_storage = sessionStorage.getItem('raid')
      if (local_storage != null) {
        local_storage = JSON.parse(local_storage)
        setBackupRaid({ ...local_storage.data.raid })
      } else {
        setBackupRaid({})
      }
    }

    window.addEventListener('storage', checkData)

    return () => {
      window.removeEventListener('storage', checkData)
    }
  }, [])

  const filterOptions = (options, state) => {
    var list = defaultFilterOptions(options, state).slice(0, 4);
    return list;
  };

  const filterOptionsPokemon = (options, state) => {
    var list = defaultFilterOptions(options, state).slice(0, 4);
    list.push({ 'name': 'Any' });
    return list;
  }
  const pokemonHandler = (value) => {
    setPokemonSelected(value);
  }
  const starHandler = (star) => {
    setStarSelected(star)
    setPokemonsFiltered([...pokemonsData?.filter(pokemon => JSON.parse(pokemon.stars).includes(starSelected))]);
  }
  const eventHandler = (value) => {
    setEventSelected(value);
  }
  const teraTypeHandler = (value) => {
    setTeratypeSelected(value);
  }

  const filter = () => {
    const event = eventSelected;
    const pokemon = pokemonSelected != null ? pokemonSelected.name : 'Any';
    const stars = starSelected;
    const teratype = teratypeSelected;

    var filters = {};
    if (event != -1) filters['is_event'] = event;
    if (stars != -1) filters['stars'] = stars;
    if (teratype != 'Any') filters['tera_type'] = teratype;
    if (pokemon != 'Any') filters['pokemon_name'] = pokemon;

    const filterKeys = Object.keys(filters);
    setRaidsFiltered(raids.filter(raid => {
      return filterKeys.every(key => {
        return filters[key] == raid[key];
      });
    }));
  }

  const reset = () => {
    setEventSelected(-1);
    setStarSelected(-1);
    setTeratypeSelected('Any')
  }

  return (
    <>
      <Container fluid className='mx-0' style={{ maxHeight: '100%', width: '100%' }}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <CreateRaidModal teratypes={types} pokemons={pokemonsData} close={handleAddClose} open={openAdd} />

        <JoinRaidModal raid={selectedRaid} setRaid={setSelectedRaid} close={handleJoinClose} open={openJoin} />

        <Row>
          <Col md={3}>
            <Paper variant="outlined" className='menu' >
              <Container className='my-2 fluid'>
                <Row className='my-3'>
                  <Col>
                    <h2>Peepo Raids</h2>
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col>
                    <Divider />
                  </Col>
                </Row>
                <Container id='main_conteinter'>
                  <Row className='my-3'>
                    <Col>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Stars</InputLabel>
                        <Select
                          style={{ textAlign: 'left' }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={starSelected}
                          label="Stars"
                          onChange={(event) => {
                            starHandler(event.target.value);
                          }}
                        >
                          <MenuItem value={-1}>Any</MenuItem>
                          {[...Array(7).keys()]?.map(value => (
                            <MenuItem key={value} value={value + 1}>{value + 1} Star</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className='my-3'>
                    <Col>
                      <Autocomplete
                        style={{ width: '100%', textAlign: 'left' }}
                        disablePortal
                        filterOptions={filterOptions}
                        id="pokemon-select"
                        defaultValue={''}
                        options={types}
                        value={teratypeSelected || ''}
                        onChange={(event, value) => {
                          teraTypeHandler(value);
                        }}
                        renderInput={(params) =>
                          <TextField
                            style={{ textAlign: 'left' }}
                            value={teratypeSelected || ''}
                            {...params}
                            label="Tera types" />
                        }
                      />
                    </Col>
                  </Row>
                  <Row className='my-3'>
                    <Col>
                      <Autocomplete
                        style={{ width: '100%' }}
                        disablePortal
                        filterOptions={filterOptionsPokemon}
                        id="pokemon-select"
                        options={pokemonsFiltered || []}
                        value={pokemonSelected || ''}
                        isOptionEqualToValue={(option, value) => option.name === value}
                        getOptionLabel={(option) => (option.name ? option.name : '')}
                        onChange={(event, value) => {
                          pokemonHandler(value);
                        }}
                        renderInput={(params) =>
                          <TextField
                            value={pokemonSelected || ''}
                            {...params}
                            label="Pokemon" />
                        }
                      />
                    </Col>
                  </Row>
                  <Row className='my-3'>
                    <Col>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Event</InputLabel>
                        <Select
                          style={{ textAlign: 'left' }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={eventSelected}
                          label="Stars"
                          onChange={event => eventHandler(event.target.value)}
                        >
                          <MenuItem key={1} value={'-1'}>Any</MenuItem>
                          <MenuItem key={2} value={false}>No</MenuItem>
                          <MenuItem key={3} value={true}>Yes</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                </Container>

                <Row className='my-3'>
                  <Col id='reset_filter'>
                    <Button variant="contained" onClick={() => reset()}>Reset filters</Button>
                  </Col>
                  <Col id="post_new_raid">
                    <Button variant="contained" onClick={() => { handleAddOpen() }}> Post new Raid</Button>
                  </Col>
                </Row>
              </Container>
              <Container className='icon-footer'>
                <Row className='my-3' id="peepo_info">
                  <Col>
                    <a target='_blank' href='https://twitter.com/PeepoSoftDev'><FontAwesomeIcon size='2xl' icon={faTwitter} /></a>
                  </Col>
                  <Col>
                    <a href='mailto:peeposoft@gmail.com'><FontAwesomeIcon size='2xl' icon={faEnvelope} /></a>
                  </Col>
                </Row>
              </Container>
            </Paper>
          </Col>
          <Col md={9} style={{ overflowY: 'scroll' }}>
            <Container fluid className='raids my-3' id='raids_container'>
              <Row>
                {raidsFiltered?.map((raid, index) => (
                  <Col md={6} lg={4} xxl={3} key={index} className={'p-2'} onClick={() => {
                    setSelectedRaid({ ...raid });
                    handleJoinOpen();
                  }}>
                    <PokemonCard raidInfo={raid} />
                  </Col>
                ))}
              </Row>
              <Fab color="primary" onClick={()=> {
                setOpenInfo(true)
              }}style={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 20,
                left: 'auto',
                position: 'fixed',
              }} aria-label="add">
                <HelpOutlineIcon sx={{ fontSize: 40 }} />
              </Fab>
            </Container>
          </Col>
        </Row>
      </Container>
      <Tour
        steps={steps}
        isOpen={openInfo}
        onRequestClose={() => {
          setOpenInfo(false)
        }} />
    </>
  );
}