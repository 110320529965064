import { clear } from "@testing-library/user-event/dist/clear"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { JoinRaidContext } from "."
import { API } from "../../api"

export const JoinRaidProvider = ({ children }) => {
    const [joined, setJoined] = React.useState(false)
    const [status, setStatus] = React.useState(-1)
    const [pin, setPin] = React.useState('')
    const [interval, setIntervalState] = useState();

    const checkPIN = async (id, close, interval) => {
        const { data } = await API.get(`${process.env.REACT_APP_URL_BACK}/check_pin/${id}/`);
        if (data.status == 1 && data.pin != '') {
            setStatus(3)
            setPin(data.pin)
            toast.success("PIN received successfully")
            clearInterval(interval)
        } else if (data.status == 0) {
            clearInterval(interval)
            sessionStorage.removeItem("raid")
            toast.warning("The raid has been disbanded by the host")
            close()
        }
    }
    const checkAvailability = async (raid) => {
        const { data } = await API.get(`${process.env.REACT_APP_URL_BACK}/check_availability/${raid.id}/`);
        setStatus(data.status)
        if (data.status == 1) {
            setJoined(true)
        }
        var local_storage = {
            operation: 1,
            data: {
                raid: raid,
            },
            states: {
                status: data.status,
                pin: pin
            }
        }
        sessionStorage.setItem('raid', JSON.stringify(local_storage))
    }
    const getOutFromRaid = async (payload, close) => {
        const { data } = await API.post(`${process.env.REACT_APP_URL_BACK}/go_out_from_raid/`, payload);
        if (data.status === 1) {
            sessionStorage.removeItem('raid')
            close()
        }
    }
    return (
        <JoinRaidContext.Provider value={{
            checkPIN,
            checkAvailability,
            getOutFromRaid,
            joined,
            setJoined,
            status,
            setStatus,
            pin,
            setPin,
            interval,
            setIntervalState
        }}>
            {children}
        </JoinRaidContext.Provider>
    )
}