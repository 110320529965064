

import * as React from 'react';
import { Box } from '@mui/material';
import './index.css'
import 'react-toastify/dist/ReactToastify.css';
import { CRModal } from './modal';
import Modal from 'react-bootstrap/Modal';

import { CreateRaidProvider } from '../../context/CreateRaid/Provider';
import { DashboardProvider } from '../../context/Dashboard/Provider';

export function CreateRaidModal({ teratypes, pokemons, close, open }) {

  return (
    <Modal
      show={open}
      onHide={close}
      backdrop="static"
      size="lg"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Modal.Header closeButton>
        <Modal.Title>CREATE NEW RAID</Modal.Title>
      </Modal.Header>

      <CreateRaidProvider>
        <CRModal teratypes={teratypes} pokemons={pokemons} close={close} open={open} />
      </CreateRaidProvider>

    </Modal>
  );
}