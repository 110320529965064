import axios from 'axios'
import { toast } from 'react-toastify';


export const API = axios.create({
  baseURL: process.env.REACT_APP_URL_BACK
})


API.interceptors.response.use(
  (response) => response,
  (error) => {
    toast.danger("Something went wrong")
    console.log(error)
    throw error;
  }
);

