import React, { useState } from "react"
import { DashboardContext } from "."
import { API } from "../../api"

export const DashboardProvider = ({ children }) => {

  const [raids, setRaids] = useState([])
  const [raidsFiltered, setRaidsFiltered] = useState([])
  const [pokemonsData, setPokemonsData] = useState([])
  const [pokemonsFiltered, setPokemonsFiltered] = useState([]);

  const getRaids = async () => {
    const { data } = await API.get(`${process.env.REACT_APP_URL_BACK}/raids/`);
    setRaids([...data]);
    setRaidsFiltered([...data]);
  }
  const getPokemons = async () => {
    const { data } = await API.get(`${process.env.REACT_APP_URL_BACK}/pokemons/`);
    setPokemonsData([...data]);
    setPokemonsFiltered([...data]);
  }
  const updateRaids = (raid, operation) => {
    if (operation == "add") {
      setRaids([...raids, raid])
      setRaidsFiltered([...raidsFiltered, raid])
    } else {
      var delated = raids.filter(function (obj) {
        return obj.id !== raid.id;
      });
      setRaids(delated)
      setRaidsFiltered(delated)
    }
  }

  return (
    <DashboardContext.Provider value={{
      raids,
      setRaids,
      raidsFiltered,
      setRaidsFiltered,
      getRaids,
      getPokemons,
      pokemonsData,
      pokemonsFiltered,
      setPokemonsFiltered,
      updateRaids
    }}>
      {children}
    </DashboardContext.Provider>
  )
}