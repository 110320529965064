import * as React from 'react';
import { Container, Button, } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { FormControl, InputLabel, Select, MenuItem, Fab } from '@mui/material';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import ProgressBar from 'react-bootstrap/ProgressBar';
import PokemonCard from '../PokemonCard'
import './index.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tour from 'reactour'

import { CreateRaidContext } from '../../context/CreateRaid';
import { DashboardContext } from '../../context/Dashboard';

const row_stylized = {
  textAlign: 'center', justifyContent: 'center',
  alignItems: 'center', marginTop: '1em'
};

export function CRModal({ teratypes, pokemons, close, open }) {

  const steps = [
    {
      selector: '#raid_values',
      content: 'Enter the values of the raid before starting it in the game, so you will have more time to find the people.'
    },
    {
      selector: '#post_raid',
      content: 'Post the raid'
    }
  ]

  const {
    getCompliance,
    postNewRaid,
    assignPin,
    disbandRaid,
    isPosted,
    setIsPosted,
    players,
    setPlayers,
    partyComplete,
    setPartyComplete,
    newRaidResponse,
    setNewRaidResponse,
    shared,
    setShared,
    pin,
    setPin,
    interval,
    setIntervalState
  } = React.useContext(CreateRaidContext)

  const {
    updateRaids,
  } = React.useContext(DashboardContext)

  const [pokemonSelected, setPokemonSelected] = React.useState();
  const [starSelected, setStarSelected] = React.useState()
  const [teratypeSelected, setTeratypeSelected] = React.useState()
  const [eventSelected, setEventSelected] = React.useState()
  const [raid, setRaid] = React.useState({ is_event: false });
  const [changePinButtonDisabled, setChangePinButtonDisabled] = React.useState(false)
  const [postRaidButtonDisabled, setPostRaidButtonDisabled] = React.useState(false)
  const [disbandRaidButtonDisabled, setDisbandRaidButtonDisabled] = React.useState(false)
  const [openInfo, setOpenInfo] = React.useState(false)

  const card = React.useRef();

  const defaultFilterOptions = createFilterOptions();

  React.useEffect(() => {
    var local_storage = sessionStorage.getItem('raid');
    if (local_storage != null) {
      local_storage = JSON.parse(local_storage);
      if (local_storage['operation'] == 0) {
        toast.warning('You have another raid created');
        setRaid(local_storage.data.raid);
        setPokemonSelected(local_storage.data.pokemon);
        setStarSelected(local_storage.data.stars);
        setTeratypeSelected(local_storage.data.tera_type);
        setEventSelected(local_storage.data.is_event);
        console.log(local_storage.data.is_event)
        setIsPosted(local_storage.states.is_posted)
        setPlayers(local_storage.states.players)
        setPartyComplete(local_storage.states.party_complete)
        setPin(local_storage.states.pin)
        setShared(local_storage.states.shared)
        setNewRaidResponse(local_storage.states.new_raid_response)
        card.current.change_state(local_storage.data.raid);
      }
    }
  }, [])

  React.useEffect(() => {
    var interval = null;
    if (isPosted === true && newRaidResponse != undefined) {
      getCompliance(newRaidResponse.id)
      interval = setInterval(() => getCompliance(newRaidResponse.id), 5000);
      setIntervalState(interval)
      return (() => {
        setIsPosted(false)
        clearInterval(interval)
      })
    }
  }, [isPosted])


  const pokemonHandler = (value) => {
    var r = { ...raid }
    r.pokemon = value.pokedex_number;
    r.pokemon_name = value.name;
    setRaid(r)
    card.current.change_state(r);
    setPokemonSelected(value);
  }
  const starHandler = (star) => {
    var r = { ...raid }
    r.stars = parseInt(star);
    setRaid(r)
    card.current.change_state(r);
    setStarSelected(star)
  }
  const eventHandler = (value) => {
    var r = { ...raid }
    r.is_event = value;
    setRaid(r)
    card.current.change_state(r);
    console.log(value)
    setEventSelected(value);
  }
  const teraTypeHandler = (value) => {
    var r = { ...raid }
    r.tera_type = value;
    setRaid(r)
    card.current.change_state(r);
    setTeratypeSelected(value);
  }

  const filterOptions = (options, state) => {
    var list = defaultFilterOptions(options, state).slice(0, 4);
    return list;
  };
  const filterOptionsPokemon = (options, state) => {
    var list = defaultFilterOptions(options, state).slice(0, 4);
    return list;
  }

  const pinHandler = (value) => {
    if (value?.length == 0) setPin(value)
    if (value?.length <= 6 && /^[a-hj-np-yA-HJ-NP-Y]+$/.test(value)) {
      setPin(value.toUpperCase())
    }
  }

  const changePin = () => {
    if (changePinButtonDisabled) {
      return;
    }
    setChangePinButtonDisabled(true)
    if (pin?.length == 6 && /^[a-hj-np-yA-HJ-NP-Y]+$/.test(pin)) {
      var payload = new FormData();
      payload.append('id', newRaidResponse.id)
      payload.append('pin', pin);
      assignPin(payload);
    } else {
      toast.error('The PIN does not meet all the requirements');
    }
  }

  const postRaid = () => {
    if (postRaidButtonDisabled) {
      return;
    }
    if (pokemonSelected != undefined && pokemonSelected.pokedex_number != undefined && starSelected != undefined && teratypeSelected != undefined && eventSelected != undefined) {
      var payload = {
        pokemon: pokemonSelected,
        stars: starSelected,
        tera_type: teratypeSelected,
        is_event: eventSelected
      }
      setPostRaidButtonDisabled(true)
      postNewRaid(payload, raid, updateRaids);
    } else {
      toast.warning("Some values ​​are missing")
    }

  }
  const disband = () => {
    if (disbandRaidButtonDisabled) {
      return;
    }
    setDisbandRaidButtonDisabled(true)
    var payload = new FormData();
    payload.append('id', newRaidResponse.id)
    disbandRaid(payload, updateRaids, close)
  }

  const startRaid = () => {
    setPartyComplete(true)
  }
  return (
    <div className='oval_div_modal_raid'>
      <Container style={{ width: '80%' }}>
        <Row>
          <Col>
            <Row>
              <Col>
                <Container id='raid_values'>
                  <Row style={row_stylized}>
                    <Col>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Stars</InputLabel>
                        <Select
                          disabled={isPosted}
                          style={{ textAlign: 'left' }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={starSelected || ''}
                          label="Stars"
                          onChange={(event) => {
                            starHandler(event.target.value);
                          }}
                        >
                          {[...Array(7).keys()].map(value => (
                            <MenuItem key={value} value={value + 1}>{value + 1} Star</MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                    </Col>
                    <Col>
                      <Autocomplete
                        disabled={isPosted}
                        style={{ width: '100%', textAlign: 'left' }}
                        disablePortal
                        filterOptions={filterOptions}
                        id="pokemon-select"
                        options={teratypes.slice(1)}
                        onChange={(event, value) => {
                          teraTypeHandler(value);
                        }}
                        value={teratypeSelected || ''}
                        disableClearable={true}
                        renderInput={(params) =>
                          <TextField
                            style={{ textAlign: 'left' }}
                            value={teratypeSelected || ''}
                            {...params}
                            label="Tera types" />
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={row_stylized}>
                    <Col>
                      <Autocomplete
                        disabled={isPosted}
                        style={{ width: '100%' }}
                        disablePortal
                        filterOptions={filterOptionsPokemon}
                        id="pokemon-select"
                        options={pokemons}
                        disableClearable={true}
                        getOptionLabel={(option) => (option.name ? option.name : '')}
                        onChange={(event, value) => {
                          pokemonHandler(value);
                        }}
                        value={pokemonSelected || ''}
                        renderInput={(params) =>
                          <TextField
                            value={pokemonSelected || ''}
                            {...params}
                            label="Pokemon" />
                        }
                      />
                    </Col>
                    <Col>
                      <FormControl fullWidth>
                        <InputLabel disabled={isPosted} id="demo-simple-select-label-modal">Event</InputLabel>
                        <Select
                          disabled={isPosted}
                          style={{ textAlign: 'left' }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={eventSelected}
                          label="event"
                          onChange={event => eventHandler(event.target.value)}
                        >
                          <MenuItem key={1} value={0}>No</MenuItem>
                          <MenuItem key={2} value={1}>Yes</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row style={row_stylized}>
              <Col>
                {
                  (isPosted == true && partyComplete == false) &&
                  <Container>
                    <h3>Waiting for players...</h3>
                    <ProgressBar style={{ height: '50px' }} animated variant="success" now={(players <= 4 ? players : 4) * 100 / 4} label={`${players <= 4 ? players : 4}`} />
                  </Container>
                }
                {
                  (partyComplete == true) &&
                  <Container style={{ marginTop: '1em' }}>
                    <h4>Raid is ready, share the raid PIN</h4>
                    <TextField
                      disabled={shared}
                      style={{ width: '100%' }}
                      id="filled-textarea"
                      label=""
                      placeholder="Raid PIN"
                      value={pin}
                      variant="filled"
                      onChange={event => pinHandler(event.target.value)}
                    />
                    <Button style={{ marginTop: '1em' }} disabled={shared} variant="success" onClick={() => { changePin() }}>Share PIN</Button>
                  </Container>
                }
              </Col>
            </Row>
            <Row style={row_stylized}>
              <Col>
                {isPosted === false ?
                  <Button id='post_raid' variant="success" onClick={() => { postRaid(); }}>Post Raid</Button>
                  :
                  <Container >
                    <Row>
                      <Col>
                        <Button variant="danger" onClick={() => { disband(); }}>Disband Raid</Button>
                      </Col>
                      {partyComplete == false &&
                        <Col>
                          <Button variant="success" onClick={() => { startRaid(); }}>Start raid</Button>
                        </Col>
                      }
                    </Row>
                  </Container>
                }
              </Col>
            </Row>

          </Col>

          <Col>
            <PokemonCard ref={card} raidInfo={raid} />
          </Col>
        </Row>
      </Container>
      {isPosted==false &&

      <Fab color="primary" onClick={() => {
        setOpenInfo(true)
      }} style={{
        position: 'absolute',
        marginBottom: '1em',
        bottom: 10,
        right: 10
      }} aria-label="add">
        <HelpOutlineIcon sx={{ fontSize: 40 }} />
      </Fab>
      }
      <Tour
        steps={steps}
        isOpen={openInfo}
        onRequestClose={() => {
          setOpenInfo(false)
        }} />
    </div>
  );
}