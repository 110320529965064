import React, { useState } from "react"
import { toast } from "react-toastify";
import { CreateRaidContext } from "."
import { API } from "../../api"

export const CreateRaidProvider = ({ children }) => {
    const [isPosted, setIsPosted] = useState(false);
    const [players, setPlayers] = useState(1);
    const [partyComplete, setPartyComplete] = useState(false);
    const [newRaidResponse, setNewRaidResponse] = useState({});
    const [shared, setShared] = useState(false);
    const [pin, setPin] = useState("")
    const [interval, setIntervalState] = useState();

    const getCompliance = async (id) => {
        const { data } = await API.get(`${process.env.REACT_APP_URL_BACK}/check_compliance/${id}/`);
        setPlayers(data.players)
        if (data.status === 2 && data.players === 4) {
            setPartyComplete(true)
            clearInterval(interval)
        }
    }

    const postNewRaid = async (payload, raid, updateRaids) => {
        var payloadForm = new FormData();
        payloadForm.append('pokemon', payload.pokemon.id);
        payloadForm.append('stars', payload.stars);
        payloadForm.append('tera_type', payload.tera_type);
        payloadForm.append('is_event', payload.is_event);
        const { data, status } = await API.post(`${process.env.REACT_APP_URL_BACK}/raids/`, payloadForm);
        if(status == 201){
            raid['players_abroad'] = 1
            updateRaids(raid, "add")
            setNewRaidResponse(data)
            setIsPosted(true)
            var local_storage = {
                operation: 0,
                data: {
                    raid: raid,
                    pokemon: payload["pokemon"],
                    stars: payload["stars"],
                    tera_type: payload["tera_type"],
                    is_event: payload["is_event"]
                },
                states: {
                    is_posted: true,
                    players: players,
                    party_complete: partyComplete,
                    new_raid_response: data,
                    pin: pin,
                    shared: shared
                }
            }
        }
        sessionStorage.setItem('raid', JSON.stringify(local_storage))
    }

    const assignPin = async (payload) => {
        const { data } = await API.post(`${process.env.REACT_APP_URL_BACK}/assign_pin/`, payload);
        if(data.status === 1){
            setShared(true)
            clearInterval(interval)
            toast.success("The PIN has been successfully sent")
        }
    }

    const disbandRaid = async (payload, updateRaids,close) => {
        const { data } = await API.post(`${process.env.REACT_APP_URL_BACK}/close_raid/`, payload);
        if(data.status === 1){
            updateRaids(payload,"delete")
            sessionStorage.removeItem('raid')
            toast.success("The raid has been successfully disbanded")
            close()
        }
    }

    return (
        <CreateRaidContext.Provider value={{
            getCompliance,
            postNewRaid,
            assignPin,
            disbandRaid,
            isPosted,
            setIsPosted,
            players,
            setPlayers,
            partyComplete,
            setPartyComplete,
            newRaidResponse,
            setNewRaidResponse,
            shared,
            setShared,
            pin,
            setPin,
            interval,
            setIntervalState

        }}>
            {children}
        </CreateRaidContext.Provider>
    )
}