import React from "react";
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { JRModal } from "./modal";

import { JoinRaidProvider } from '../../context/JoinRaid/Provider';
export const JoinRaidModal = ({ raid, setRaid, open, close }) => {

  return (
    <Modal
      show={open}
      onHide={close}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>JOIN RAID</Modal.Title>
      </Modal.Header>
      <Box sx={{
        backgroundColor: 'transparent',
        outline: 'none'
      }}>
        <JoinRaidProvider>
          <JRModal raid={raid} setRaid={setRaid} open={open} close={close} />
        </JoinRaidProvider>
      </Box>
    </Modal>
  );
}

export default JoinRaidModal;