import React from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import PokemonCard from '../PokemonCard'
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { JoinRaidContext } from "../../context/JoinRaid";

export function JRModal({ raid, setRaid, open, close }) {

    const {
        checkPIN,
        checkAvailability,
        getOutFromRaid,
        joined,
        status,
        setStatus,
        pin,
        setPin,
        interval,
        setIntervalState
      } = React.useContext(JoinRaidContext)

    const [allowed, setAllowed] = React.useState(true)
    const [goOutButtonDisabled, setGoOutButtonDisabled] = React.useState(false)
    const [checkButtonDisabled, setCheckButtonDisabled] = React.useState(false)

    React.useEffect(() => {
        var local_storage = sessionStorage.getItem('raid');
        if (local_storage != null) {
            local_storage = JSON.parse(local_storage);
            if (local_storage['operation'] == 1) {
                toast.warning('You are joined in another raid');
                setRaid(local_storage.data['raid'])
                setPin(local_storage.states['pin'])
                setStatus(local_storage.states['status'])
                setAllowed(true)
            } else {
                setAllowed(false)
                toast.warning("You already posted a raid, disband it first");
            }
        }
    }, [])
    React.useEffect(() => {
        var interval = null;
        if (joined === true) {
            checkPIN(raid.id, close)
            interval = setInterval(() => {
                setIntervalState(interval)
                checkPIN(raid.id, close, interval)
            }, 5000);
            return (() => {
                clearInterval(interval)
            })
        }
    }, [joined])

    const join = () => {
        if (checkButtonDisabled) {
            return;
        }
        setCheckButtonDisabled(true);
        console.log(raid)
        checkAvailability(raid)
    }

    const goOut = () => {
        if (goOutButtonDisabled) {
            return
        }
        setGoOutButtonDisabled(true)
        if (status === 1 || status == 3) {
            var payload = new FormData();
            payload.append('id', raid.id)
            getOutFromRaid(payload, close)
        } else {
            sessionStorage.removeItem('raid')
            close()
        }
    }

    return (
        <div className='oval_div_modal_raid'>
            <Container style={{ width: '80%' }}>
                <Row>
                    <Col>
                        <Container>
                            <h4>
                                Click on the following button to save your place in this PeepoRaid
                            </h4>
                            {status === -1 ?
                                <Button style={{ marginTop: '1em' }} disabled={!allowed} variant="success" onClick={() => { join() }}>Save your place</Button>
                                :
                                <Button style={{ marginTop: '1em' }} variant="danger" onClick={() => { goOut() }}>Go out</Button>
                            }
                        </Container>
                        {(status == 0) &&
                            <Container style={{ backgroundColor: 'rgba(255, 60, 22, 0.3)', width: '100%', marginTop: '1em', borderRadius: '30px', display: 'flex', justifycontent: 'center', textAlign: 'center' }}>
                                <div style={{ width: '80%', margin: '1em auto', textAlign: 'center' }}>
                                    <h5>Sorry this raid has been disbanded by the host</h5>
                                </div>
                            </Container>
                        }
                        {(status == 1) &&
                            <Container style={{ backgroundColor: 'rgba(125, 255, 123, 0.3)', width: '100%', marginTop: '1em', borderRadius: '30px', display: 'flex', justifycontent: 'center', textAlign: 'center' }}>
                                <div style={{ width: '80%', margin: '1em auto', textAlign: 'center' }}>
                                    <h5>Your are in the raid, waiting for the host to share the raid PIN</h5>
                                    <CircularProgress color="success" />
                                </div>
                            </Container>
                        }
                        {status == 2 &&
                            <Container style={{ backgroundColor: 'rgba(255, 60, 22, 0.3)', width: '100%', marginTop: '1em', borderRadius: '30px', display: 'flex', justifycontent: 'center', textAlign: 'center' }}>
                                <div style={{ width: '80%', margin: '1em auto', textAlign: 'center' }}>
                                    <h5>Sorry this raid is already full</h5>
                                </div>
                            </Container>
                        }
                        {status == 3 &&
                            <Container style={{ marginTop: '1em' }}>
                                <h4>Raid PIN:</h4>
                                <TextField
                                    disabled
                                    inputProps={{ style: { fontSize: 40, marginTop: '0em', textAlign: 'center' } }}
                                    id="filled-textarea"
                                    variant="filled"
                                    value={pin}
                                />
                            </Container>
                        }
                    </Col>
                    <Col>
                        <PokemonCard raidInfo={raid} />
                    </Col>
                </Row>
            </Container>
        </div>
    )

}