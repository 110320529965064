import React from "react";
import { Card, Stack } from 'react-bootstrap';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Rating } from '@mui/material';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});


const PokemonCard = React.forwardRef((raidInfo, ref) => {
    const [raid, setRaid] = React.useState({ ...raidInfo.raidInfo });
    const pokedexNumberFormater = (pokedex_number) => {
        var pokedex_number = pokedex_number.toString();
        return pokedex_number?.length < 3 ? "0".repeat(3 - pokedex_number?.length) + pokedex_number : pokedex_number;
    }
    const change_state = (raid) => {
        setRaid({ ...raid });
    }
    React.useImperativeHandle(ref, () => ({
        change_state: change_state
    }));
    React.useEffect(() => {
        setRaid(raidInfo.raidInfo)
    }, [raidInfo])
    return (
        <Card className='card' style={{ height: '100%', border: '5px solid' }}>
            <Stack>
                <div id="container">
                    {
                        raid.tera_type !== undefined ?
                            <img src={`${process.env.REACT_APP_URL_FRONT}/assets/typeBg/${raid.tera_type}_Wallpaper.png`} id="img1" style={{ width: '100%', height: '260px', objectFit: 'fill' }} /> :
                            <img src={`${process.env.REACT_APP_URL_FRONT}/assets/typeBg/Normal_Wallpaper.png`} id="img1" style={{ width: '100%', height: '260px', objectFit: 'fill' }} />
                    }

                    {
                        raid.pokemon !== undefined ?
                            <img src={`https://www.serebii.net/scarletviolet/pokemon/new/${pokedexNumberFormater(raid.pokemon)}.png`} id="img2" /> :
                            <img src={`${process.env.REACT_APP_URL_FRONT}/assets/icons/question.png`} id="img2" style={{ height: '250px' }} />
                    }

                </div>
            </Stack>
            <Card.Body className={raid.teratype}>
                {raid.pokemon_name !== undefined ? <Card.Title className='text-center'>{raid.pokemon_name}</Card.Title> : <> Name: ? </>}
                <div className='text-center'>
                    {
                        raid.stars !== undefined ?
                            <StyledRating
                                name="customized-color"
                                value={raid.stars}
                                precision={1}
                                icon={
                                    raid.is_event ? <img className='me-1' src={`${process.env.REACT_APP_URL_FRONT}/assets/icons/blue-star.png`} style={{ width: '25px', height: '25px' }} /> :
                                        raid.stars > 5 ? <img className='me-1' src={`${process.env.REACT_APP_URL_FRONT}/assets/icons/pink-star.png`} style={{ width: '25px', height: '25px' }} /> :
                                            <img className='me-1' src={`${process.env.REACT_APP_URL_FRONT}/assets/icons/yellow-star.png`} style={{ width: '25px', height: '25px' }} />
                                }
                                emptyIcon={<></>}
                                readOnly
                                max={7}
                            /> :
                            <>Stars: ?</>
                    }

                </div>
                <h6 className='text-center'> Teratype: {raid.tera_type !== undefined ? raid.tera_type : '?'}</h6>
                {ref == undefined &&
                    <h6 className='text-center'> Players: {raid.players_abroad !== undefined ? raid.players_abroad : '?'}</h6>
                }

            </Card.Body>
        </Card>
    );
});
export default PokemonCard;